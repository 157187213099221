import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,[_c(VToolbar,{attrs:{"flat":"","color":"blue","dark":""}},[_c(VToolbarTitle,[_vm._v("Preferences")]),_c(VSpacer),(_vm.hasChanged)?_c(VBtn,{staticClass:"ml-5",attrs:{"elevation":"0","color":"green"},on:{"click":function($event){return _vm.savePreferences()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v("Save changes")],1):_vm._e()],1),_c(VTabs,{attrs:{"centered":"","grow":""}},[_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-format-list-text")]),_vm._v(" Formatting preferences ")],1),_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-map-marker-path")]),_vm._v(" Routing preferences ")],1),_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-shape")]),_vm._v(" Symbol preferences ")],1),_c(VTab,[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-map-marker-alert")]),_vm._v(" Advanced preferences ")],1),_c(VTabItem,[_c('preferencesFormatting',{on:{"input":function($event){return _vm.checkForChange()}},model:{value:(_vm.preferences),callback:function ($$v) {_vm.preferences=$$v},expression:"preferences"}})],1),_c(VTabItem,[_c('preferencesRouting',{on:{"input":function($event){return _vm.checkForChange()}},model:{value:(_vm.preferences),callback:function ($$v) {_vm.preferences=$$v},expression:"preferences"}})],1),_c(VTabItem,[_c('preferencesSymbol',{on:{"input":function($event){return _vm.checkForChange()}},model:{value:(_vm.preferences),callback:function ($$v) {_vm.preferences=$$v},expression:"preferences"}})],1),_c(VTabItem,[_c('preferencesAdvanced',{on:{"input":function($event){return _vm.checkForChange()}},model:{value:(_vm.preferences),callback:function ($$v) {_vm.preferences=$$v},expression:"preferences"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }