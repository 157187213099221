<template>
  <v-container>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-2">
        <v-icon class="mr-2" color="blue darken-2">mdi-speedometer</v-icon>
        Speed, range, stop and rest duration
      </v-card-title>
      <v-card-subtitle class="ml-2">
        Please provide your fuel range, typical speeds and stop durations
      </v-card-subtitle>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.overall_mph"
            thumb-label="always"
            thumb-size="30"
            step="0.5"
            min="30"
            max="50"
            label="Overall average [mph]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.moving_mph"
            thumb-label="always"
            thumb-size="30"
            step="1"
            min="45"
            max="85"
            label="Moving average [mph]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
      </v-row>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-1">
          <v-slider
            v-model="preferences.routing.fuel_stopduration"
            thumb-label="always"
            thumb-size="30"
            step="1"
            min="2"
            max="30"
            label="Fuel stop [min]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="5" class="mt-1">
          <v-slider
            v-model="preferences.routing.bonus_stopduration"
            thumb-label="always"
            thumb-size="30"
            step="1"
            min="2"
            max="30"
            label="Bonus stop [min]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
      </v-row>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-1">
          <v-slider
            v-model="preferences.routing.fuel_range"
            thumb-label="always"
            thumb-size="30"
            step="5"
            min="50"
            max="450"
            label="Fuel range [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider> </v-col
        ><v-col cols="1"></v-col>
        <v-col cols="5" class="mt-1">
          <v-slider
            v-model="preferences.routing.meal_stopduration"
            thumb-label="always"
            thumb-size="30"
            step="1"
            min="10"
            max="120"
            label="Meal stop [min]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
      </v-row>

      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-1">
          <v-slider
            v-model="preferences.routing.rest_24hours"
            thumb-label="always"
            thumb-size="30"
            step="1"
            min="0"
            max="12"
            label="Rest per 24 hours [hours]"
            @change="$emit('input', preferences)"
          >
          </v-slider> </v-col
        ><v-col cols="1"></v-col>
      </v-row>
    </v-card>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-2">
        <v-icon class="mr-2" color="blue darken-2"
          >mdi-map-marker-circle</v-icon
        >
        Distance circles
      </v-card-title>
      <v-card-subtitle class="ml-2">
        Configure distance circle radius for Garmin Basecamp (move to zero to
        disable)
      </v-card-subtitle>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.distance_1_start_radius"
            thumb-label="always"
            thumb-size="30"
            step="50"
            min="0"
            max="1500"
            label="Start 1 [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-spacer cols="1"></v-spacer>
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.distance_1_end_radius"
            thumb-label="always"
            thumb-size="30"
            step="50"
            min="0"
            max="1500"
            label="Finish 1 [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-spacer cols="1"></v-spacer>
      </v-row>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.distance_2_start_radius"
            thumb-label="always"
            thumb-size="30"
            step="50"
            min="0"
            max="1500"
            label="Start 2 [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-spacer cols="1"></v-spacer>
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.distance_2_end_radius"
            thumb-label="always"
            thumb-size="30"
            step="50"
            min="0"
            max="1500"
            label="Finish 2 [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-spacer cols="1"></v-spacer>
      </v-row>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.distance_3_start_radius"
            thumb-label="always"
            thumb-size="30"
            step="50"
            min="0"
            max="1500"
            label="Start 3 [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-spacer cols="1"></v-spacer>
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.distance_3_end_radius"
            thumb-label="always"
            thumb-size="30"
            step="50"
            min="0"
            max="1500"
            label="Finish 3 [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-spacer cols="1"></v-spacer>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { PreferencesPrototype } from '@/components/prototypes/preferences'
export default {
  name: 'PreferencesRouting',
  props: {
    value: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      preferences: {}
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },
  created: function () {
    this.preferences = { ...this.Preferences }
    this.preferences.__proto__ = PreferencesPrototype.prototype
  },
  methods: {}
}
</script>

<style></style>
