<template>
  <v-container>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-2">
        <v-icon class="mr-2" color="blue darken-2">mdi-map-marker</v-icon>Symbol
        colors
      </v-card-title>
      <v-card-subtitle class="ml-2">
        Choose a symbol color for each bonus category
      </v-card-subtitle>
      <v-row dense class="ml-5">
        <v-col cols="4">
          <v-radio-group
            v-model="preferences.symbol.color.A"
            mandatory
            dense
            row
            @change="$emit('input', preferences)"
          >
            <template v-slot:label>
              <div><strong>All-day</strong></div>
            </template>
            <v-radio label="green" color="green" value="green"></v-radio>
            <v-radio label="blue" color="blue" value="blue"></v-radio>
            <v-radio
              label="red"
              color="red"
              value="red"
            ></v-radio> </v-radio-group
        ></v-col>
        <v-col cols="4">
          <v-radio-group
            v-model="preferences.symbol.color.D"
            mandatory
            dense
            row
            @change="$emit('input', preferences)"
          >
            <template v-slot:label>
              <div><strong>Daylight</strong></div>
            </template>
            <v-radio label="green" color="green" value="green"></v-radio>
            <v-radio label="blue" color="blue" value="blue"></v-radio>
            <v-radio
              label="red"
              color="red"
              value="red"
            ></v-radio> </v-radio-group
        ></v-col>
        <v-col cols="4">
          <v-radio-group
            v-model="preferences.symbol.color.T"
            mandatory
            dense
            row
            @change="$emit('input', preferences)"
          >
            <template v-slot:label>
              <div><strong>Timed</strong></div>
            </template>
            <v-radio label="green" color="green" value="green"></v-radio>
            <v-radio label="blue" color="blue" value="blue"></v-radio>
            <v-radio
              label="red"
              color="red"
              value="red"
            ></v-radio> </v-radio-group></v-col
      ></v-row>
    </v-card>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-2">
        <v-icon class="mr-2" color="blue darken-2">mdi-map-marker</v-icon
        >Symbols
      </v-card-title>
      <v-card-subtitle class="ml-2">
        Choose a symbol for each bonus category
      </v-card-subtitle>
      <v-row dense class="ml-5">
        <v-col cols="2">
          <v-select
            v-model="preferences.symbol.tshirt.small.symbol"
            :items="symbols"
            label="Small"
            dense
            @change="$emit('input', preferences)"
          ></v-select> </v-col
        ><v-col class="mt-2" cols="1">
          <img
            :src="determineSymbolIcon(preferences.symbol.tshirt.small.symbol)"
            alt=""
            height="25"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="preferences.symbol.tshirt.medium.symbol"
            :items="symbols"
            label="Medium"
            dense
            @change="$emit('input', preferences)"
          ></v-select> </v-col
        ><v-col class="mt-2" cols="1">
          <img
            :src="determineSymbolIcon(preferences.symbol.tshirt.medium.symbol)"
            alt=""
            height="25"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="preferences.symbol.tshirt.large.symbol"
            :items="symbols"
            label="Large"
            dense
            @change="$emit('input', preferences)"
          ></v-select> </v-col
        ><v-col class="mt-2" cols="1">
          <img
            :src="determineSymbolIcon(preferences.symbol.tshirt.large.symbol)"
            alt=""
            height="25"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="preferences.symbol.tshirt.extralarge.symbol"
            :items="symbols"
            label="Extra large"
            dense
            @change="$emit('input', preferences)"
          ></v-select> </v-col
        ><v-col class="mt-2" cols="1">
          <img
            :src="
              determineSymbolIcon(preferences.symbol.tshirt.extralarge.symbol)
            "
            alt=""
            height="25"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { GARMINSYMBOLS } from '@/components/prototypes/constants.js'
import { PreferencesPrototype } from '@/components/prototypes/preferences'
export default {
  name: 'PreferencesSymbol',
  props: {
    value: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      preferences: {},
      symbols: []
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },
  created: function () {
    this.preferences = { ...this.Preferences }
    this.preferences.__proto__ = PreferencesPrototype.prototype
    this.symbols = Object.entries(GARMINSYMBOLS).reduce((sum, current) => {
      sum.push(current[1].display)
      return sum
    }, [])
  },
  methods: {
    determineSymbolIcon(_selectedSymbol) {
      return `https://firebasestorage.googleapis.com/v0/b/rally-bonus-planner.appspot.com/o/icons%2Fwpt_${
        GARMINSYMBOLS[_selectedSymbol.replace(/ /g, '')]['blue']
      }.png?alt=media`
    }
  }
}
</script>
