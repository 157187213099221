<template>
  <v-container>
    <v-card>
      <v-toolbar flat color="blue" dark>
        <v-toolbar-title>Preferences</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="hasChanged"
          class="ml-5"
          elevation="0"
          color="green"
          @click="savePreferences()"
          ><v-icon left>mdi-upload</v-icon>Save changes</v-btn
        >
      </v-toolbar>
      <v-tabs centered grow>
        <v-tab>
          <v-icon left>mdi-format-list-text</v-icon>
          Formatting preferences
        </v-tab>
        <v-tab>
          <v-icon left>mdi-map-marker-path</v-icon>
          Routing preferences
        </v-tab>
        <v-tab>
          <v-icon left>mdi-shape</v-icon>
          Symbol preferences
        </v-tab>
        <v-tab>
          <v-icon left>mdi-map-marker-alert</v-icon>
          Advanced preferences
        </v-tab>
        <v-tab-item>
          <preferencesFormatting
            v-model="preferences"
            @input="checkForChange()"
          />
        </v-tab-item>
        <v-tab-item>
          <preferencesRouting v-model="preferences" @input="checkForChange()" />
        </v-tab-item>
        <v-tab-item>
          <preferencesSymbol v-model="preferences" @input="checkForChange()" />
        </v-tab-item>
        <v-tab-item>
          <preferencesAdvanced
            v-model="preferences"
            @input="checkForChange()"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import preferencesFormatting from '@/components/preferences/preferencesFormatting'
import preferencesRouting from '@/components/preferences/preferencesRouting'
import preferencesSymbol from '@/components/preferences/preferencesSymbol'
import preferencesAdvanced from '@/components/preferences/preferencesAdvanced'
import { PreferencesPrototype } from '@/components/prototypes/preferences'

export default {
  name: 'Preferences',
  components: {
    preferencesFormatting,
    preferencesRouting,
    preferencesSymbol,
    preferencesAdvanced
  },
  data() {
    return {
      preferences: {},
      hasChanged: false
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },
  created: function () {
    this.preferences = { ...this.Preferences }
    this.preferences.__proto__ = PreferencesPrototype.prototype
    this.hasChanged = false
  },
  methods: {
    ...mapActions('modulePreferences', ['PreferencesUpdateAction']),
    checkForChange() {
      if (JSON.stringify(this.preferences) === JSON.stringify(this.Preferences))
        this.hasChanged = true
    },
    savePreferences() {
      this.hasChanged = false
      this.PreferencesUpdateAction(JSON.parse(JSON.stringify(this.preferences)))
    }
  }
}
</script>

<style></style>
