<template>
  <v-container>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-2">
        <v-icon class="mr-2" color="blue darken-2">mdi-hand-right</v-icon>
        Advanced preferences
      </v-card-title>
      <v-card-subtitle class="ml-3"> For the brave ones only! </v-card-subtitle>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.vincenty_distance_adjustment"
            thumb-label="always"
            thumb-size="30"
            step="0.01"
            min="1"
            max="1.5"
            label="Vincenty distance adjustment"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.cluster_proximity"
            thumb-label="always"
            thumb-size="30"
            step="1"
            min="1"
            max="50"
            label="Cluster proximity [miles]"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
      </v-row>
      <v-row dense class="ml-5">
        <v-col cols="5" class="mt-4">
          <v-slider
            v-model="preferences.routing.average_speed_adjustment"
            thumb-label="always"
            thumb-size="30"
            step="0.01"
            min="1"
            max="1.5"
            label="Average speed adjustment"
            @change="$emit('input', preferences)"
          >
          </v-slider>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols="5" class="mt-4"> </v-col>
      </v-row>
      <v-row dense class="ml-6">
        <v-col cols="1" class="mt-2"
          ><div class="grey--text text--darken-2">API keys</div></v-col
        >
        <v-col cols="4">
          <v-text-field
            v-model="preferences.secrets.GOOGLE_API_KEY"
            dense
            label="Google API key"
            :append-icon="showGOOGLE_API_KEY ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showGOOGLE_API_KEY ? 'text' : 'password'"
            @click:append="showGOOGLE_API_KEY = !showGOOGLE_API_KEY"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"
                ><v-icon :color="colorGOOGLE_API_KEY">mdi-api</v-icon></v-btn
              ></template
            ><span v-if="colorGOOGLE_API_KEY === 'red'"
              >The API key is either missing or incorrect</span
            >
            <span v-else>The API key is working correctly</span>
          </v-tooltip>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="preferences.secrets.ORS_API_KEY"
            dense
            label="Open Route Service API key"
            :append-icon="showORS_API_KEY ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showORS_API_KEY ? 'text' : 'password'"
            @click:append="showORS_API_KEY = !showORS_API_KEY"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"
                ><v-icon :color="colorORS_API_KEY">mdi-api</v-icon></v-btn
              ></template
            ><span v-if="colorORS_API_KEY === 'red'"
              >The API key is either missing or incorrect</span
            >
            <span v-else>The API key is working correctly</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row dense class="ml-6">
        <v-col cols="1" class="mt-2"></v-col>
        <v-col cols="4">
          <v-text-field
            v-model="preferences.secrets.OWM_API_KEY"
            dense
            label="Open Weather Map API key"
            :append-icon="showOWM_API_KEY ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showOWM_API_KEY ? 'text' : 'password'"
            @click:append="showOWM_API_KEY = !showOWM_API_KEY"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"
                ><v-icon :color="colorOWM_API_KEY">mdi-api</v-icon></v-btn
              ></template
            ><span v-if="colorOWM_API_KEY === 'red'"
              >The API key is either missing or incorrect</span
            >
            <span v-else>The API key is working correctly</span>
          </v-tooltip>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="preferences.secrets.ONW_API_KEY"
            dense
            label="On Water API key"
            :append-icon="showONW_API_KEY ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showONW_API_KEY ? 'text' : 'password'"
            @click:append="showONW_API_KEY = !showONW_API_KEY"
          >
          </v-text-field>
        </v-col>
        <v-col cols="1">
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on"
                ><v-icon :color="colorONW_API_KEY">mdi-api</v-icon></v-btn
              ></template
            ><span v-if="colorONW_API_KEY === 'red'"
              >The API key is either missing or incorrect</span
            >
            <span v-else>The API key is working correctly</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { PreferencesPrototype } from '@/components/prototypes/preferences'
import { DateTime } from 'luxon'
export default {
  name: 'PreferencesAdvanced',
  props: {
    value: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      preferences: {},
      showONW_API_KEY: false,
      colorONW_API_KEY: 'red',
      showOWM_API_KEY: false,
      colorOWM_API_KEY: 'red',
      showORS_API_KEY: false,
      colorORS_API_KEY: 'red',
      showGOOGLE_API_KEY: false,
      colorGOOGLE_API_KEY: 'red'
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },
  created: async function () {
    this.preferences = { ...this.Preferences }
    this.preferences.__proto__ = PreferencesPrototype.prototype

    const axios = require('axios')
    let axiosQuery = `https://api.onwater.io/api/v1/results/29.53665073093226,-95.57989393055779?access_token=${this.Preferences.secrets.ONW_API_KEY}`
    axios
      .get(axiosQuery)
      .then(() => {
        this.colorONW_API_KEY = 'green'
      })
      .catch(() => {
        this.colorONW_API_KEY = 'red'
      })

    axiosQuery = `https://api.openrouteservice.org/v2/directions/driving-car?api_key=${this.Preferences.secrets.ORS_API_KEY}&start=8.681495,49.41461&end=8.687872,49.420318`
    axios
      .get(axiosQuery)
      .then(() => {
        this.colorORS_API_KEY = 'green'
      })
      .catch(() => {
        this.colorORS_API_KEY = 'red'
      })

    axiosQuery = `https://api.openweathermap.org/data/2.5/onecall?lat=29.53665073093226&lon=-95.57989393055779&units=imperial&exclude=minutely,hourly&appid=${this.Preferences.secrets.OWM_API_KEY}`
    axios
      .get(axiosQuery)
      .then(() => {
        this.colorOWM_API_KEY = 'green'
      })
      .catch(() => {
        this.colorOWM_API_KEY = 'red'
      })

    axiosQuery = `https://maps.googleapis.com/maps/api/timezone/json?location=29.53665073093226,-95.57989393055779&timestamp=${Math.floor(
      DateTime.now().toFormat('x') / 1000
    )}&key=${this.Preferences.secrets.GOOGLE_API_KEY}`
    axios
      .get(axiosQuery)
      .then((result) => {
        if (result.data.status === 'OK') {
          this.colorGOOGLE_API_KEY = 'green'
        } else {
          this.colorGOOGLE_API_KEY = 'red'
        }
      })
      .catch(() => {
        this.colorGOOGLE_API_KEY = 'red'
      })
  },
  methods: {}
}
</script>

<style></style>
