<template>
  <v-container>
    <v-alert dense dismissible :value="showRemoveAlert" outlined type="error">
      You must keep <strong>at least one</strong> field in the bonus name
    </v-alert>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-3">
        <v-icon class="mr-2" color="blue darken-2"
          >mdi-format-horizontal-align-center</v-icon
        >Bonus name format
      </v-card-title>
      <v-card-subtitle class="ml-3">
        Drag fields and separators to configure bonus name format
      </v-card-subtitle>
      <v-card-text>
        <v-chip-group :show-arrows="true" class="ml-2">
          <draggable :list="draggableList" :move="draggableCheckMove">
            <v-chip
              v-for="element in draggableList"
              :key="element.id"
              close
              pill
              small
              :color="
                element.category === 'separator'
                  ? 'blue lighten-4'
                  : 'blue lighten-2'
              "
              @click:close="draggableRemove(element)"
            >
              {{ element.name }}
            </v-chip>
          </draggable>
        </v-chip-group>
        <div class="ml-3 body-1">
          <v-form ref="addFieldForm">
            <v-row dense>
              <v-col cols="3">
                <v-select
                  v-model="addSeparator"
                  dense
                  :items="['-', ' ', '*', '/', '|']"
                  label="Add a separator"
                  @change="draggableAdd(false)"
                ></v-select>
              </v-col>
              <v-col cols="1"></v-col>
              <v-col cols="3">
                <v-select
                  v-model="addField"
                  dense
                  :items="draggableFields"
                  label="Add a field"
                  @change="draggableAdd(true)"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-3">
        <v-icon class="mr-2" color="blue darken-2"
          >mdi-format-letter-matches</v-icon
        >Field format
      </v-card-title>
      <v-card-subtitle class="ml-3">
        Provide formatting for the individual fields making up the bonus name
      </v-card-subtitle>
      <v-card-text>
        <v-row dense class="ml-2">
          <v-col cols="3">
            <v-select
              v-model="preferences.format.fields.page"
              :items="['79', 'P79']"
              label="Rally book page"
              dense
              @change="$emit('input', preferences)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="preferences.format.fields.unpaved"
              :items="['!', '(!)', 'UNPVD']"
              label="Unpaved"
              dense
              @change="$emit('input', preferences)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="preferences.format.fields.t_time"
              :items="['7A-5P', '(7A-5P)']"
              label="Time restrictions"
              dense
              @change="$emit('input', preferences)"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="preferences.format.fields.d_time"
              :items="['6:24-19:35', '(6:24-19:35)']"
              label="Time zone"
              dense
              @change="$emit('input', preferences)"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card color="blue lighten-5" flat class="mb-6">
      <v-card-title class="ml-3">
        <v-icon class="mr-2" color="blue darken-2">mdi-eye-outline</v-icon
        >Example format
      </v-card-title>
      <v-card-subtitle class="ml-3">
        Format examples for the main three bonus categories: all-day, daylight,
        timed
      </v-card-subtitle>
      <v-row dense class="ml-6">
        <v-col cols="1" class="mt-2">Paved</v-col>
        <v-col cols="3">
          <v-text-field
            dense
            label="Example formatting: all-day"
            :value="exampleFormatting('A', false)"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            label="Example formatting: daylight"
            :value="exampleFormatting('D', false)"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            label="Example formatting: timed"
            :value="exampleFormatting('T', false)"
            readonly
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="ml-6">
        <v-col cols="1" class="mt-2">Unpaved</v-col>
        <v-col cols="3">
          <v-text-field
            dense
            label="Example formatting: all-day"
            :value="exampleFormatting('A', true)"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            label="Example formatting: daylight"
            :value="exampleFormatting('D', true)"
            readonly
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            dense
            label="Example formatting: timed"
            :value="exampleFormatting('T', true)"
            readonly
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { PreferencesPrototype } from '@/components/prototypes/preferences'
import { BonusLocationsPrototype } from '@/components/prototypes/bonuslocations.js'
import draggable from 'vuedraggable'
export default {
  name: 'PreferencesFormatting',
  components: { draggable },
  props: {
    value: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showRemoveAlert: null,
      addSeparator: null,
      addField: null,
      preferences: {},
      draggableFields: [
        'Bonus ID',
        'Points',
        'Category',
        'Unpaved',
        'Time restrictions',
        'Rally book page',
        'Timezone'
      ],
      draggableList: [
        { name: 'Bonus ID', id: 0, category: 'field' },
        { name: '-', id: 6, category: 'separator' },
        { name: 'Category', id: 1, category: 'field' },
        { name: '-', id: 7, category: 'separator' },
        { name: 'Points', id: 2, category: 'field' },
        { name: 'Unpaved', id: 3, category: 'field' },
        { name: 'Time restrictions', id: 4, category: 'field' },
        { name: 'Rally book page', id: 5, category: 'field' }
      ],
      exampleLocation: {
        name: 'RBP',
        latitude: 44.902517,
        longitude: -92.783133,
        description: '24 hours',
        points: 1250,
        timerestrictions: '7A-9P',
        page: '21'
      }
    }
  },
  computed: {
    ...mapState({
      Preferences: (state) => state.modulePreferences.Preferences
    })
  },

  created: function () {
    this.exampleLocation.__proto__ = BonusLocationsPrototype.prototype
    this.exampleLocation.setSunset()
    this.exampleLocation.setSunrise()
    this.exampleLocation.setTimezone()

    this.preferences = { ...this.Preferences }
    this.preferences.__proto__ = PreferencesPrototype.prototype
    this.draggableList = []
    for (let item in this.Preferences.format.field_order) {
      this.draggableList.push({
        name: this.Preferences.format.field_order[item].name,
        id: item,
        category: this.Preferences.format.field_order[item].category
      })
    }
  },
  methods: {
    exampleFormatting(_category, _unpaved) {
      this.exampleLocation.unpaved = _unpaved
      this.exampleLocation.category = _category
      this.exampleLocation.setLongname()
      return this.exampleLocation.longname
    },
    draggableCheckMove: function () {
      this.preferences.format.field_order = this.draggableList
      this.$emit('input', this.preferences)
    },
    draggableRemove(_removeChip) {
      if (
        this.draggableList.filter(({ category }) => category === 'field')
          .length > 1 ||
        _removeChip.category === 'separator'
      ) {
        let result = this.draggableList.find(({ id }) => id === _removeChip.id)
        this.draggableList.splice(this.draggableList.indexOf(result), 1)
      } else {
        this.showRemoveAlert = true
      }
      this.preferences.format.field_order = this.draggableList
      this.$emit('input', this.preferences)
    },
    draggableAdd(_isField) {
      let max =
        this.draggableList.reduce((sum, current) => {
          if (current.id > sum) {
            return current.id
          } else {
            return sum
          }
        }, 0) + 1
      if (_isField) {
        this.draggableList.push({
          name: this.addField,
          id: max,
          category: 'field'
        })
      } else {
        this.draggableList.push({
          name: this.addSeparator,
          id: max,
          category: 'separator'
        })
      }
      this.preferences.format.field_order = this.draggableList
      this.$emit('input', this.preferences)
      this.$refs.addFieldForm.reset()
    }
  }
}
</script>

<style></style>
